import React, { useState } from "react"
// import sublinks from "../constants/links_mobile"
import { useTranslation } from "react-i18next" // 20210423

const GatsbyContext = React.createContext()

//Provider, Consumer

const GatsbyProvider = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { i18n } = useTranslation() // 20210423
  const [modalMenuOpen, setModalMenuOpen] = useState(false)
  // console.log('Gatsby context i18n -',i18n.language)
  // i18n.changeLanguage(pageContext.lang) // 20210423
  // const [links, setLinks] = useState(sublinks)
  const showModalMenu = () => {
    setModalMenuOpen(true)
  }

  const hideModalMenu = () => {
    setModalMenuOpen(false)
  }

  const toggleModalMenu = () => {
    setModalMenuOpen(!modalMenuOpen)
  }

  const showModal = () => {
    setIsModalOpen(true)
  }

  const hideModal = () => {
    setIsModalOpen(false)
  }

  const showSidebar = () => {
    setIsSidebarOpen(true)
  }
  const hideSidebar = () => {
    setIsSidebarOpen(false)
  }

  // Megamenu handling
  const [isMegaMenuOpen, setIsMegaMenuOpen] = useState(false)

  const showMega = () => {
    setIsMegaMenuOpen(true)
  }

  const hideMega = () => {
    setIsMegaMenuOpen(false)
  }

  return (
    <GatsbyContext.Provider
      // value={{ isSidebarOpen, links, showSidebar, hideSidebar }}
      value={{
        modalMenuOpen,
        showModalMenu,
        hideModalMenu,
        isSidebarOpen,
        showSidebar,
        hideSidebar,
        isMegaMenuOpen,
        showMega,
        hideMega,
        isModalOpen,
        showModal,
        hideModal,
        toggleModalMenu,
      }}
    >
      {children}
    </GatsbyContext.Provider>
  )
}

export { GatsbyContext, GatsbyProvider }
