import React from "react"
import { I18nextProvider } from "react-i18next"
import i18next from "./i18next"
import { GatsbyProvider } from "./src/context/context"
import "./src/font/inter.css"
import "./src/styles/global.css"
// import "@fontsource/merriweather"
// import "./src/styles/scroll.css"

export const wrapRootElement = ({ element }) => {
  return (
    <I18nextProvider i18n={i18next}>
      <GatsbyProvider>{element}</GatsbyProvider>
    </I18nextProvider>
  )
}
