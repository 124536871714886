import i18n from "i18next"
import { initReactI18next } from "react-i18next"

// import Backend from "i18next-http-backend"
// import LanguageDetector from "i18next-browser-languagedetector"
// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

const Languages = ["mn", "en"]

i18n
  // .use(Backend)
  // .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: "mn", // refresh hiiheer mongol bolood bhar ni boliulav
    // browserLanguageDetection: false, // ene songogdson ch english deer garch ireed blaa
    fallbackLng: "mn",
    debug: false,

    // whitelist: Languages,
    // load: "languageOnly",
    // ns: ["translation"],
    // backend: {
    //   loadPath: `/locales/{{lng}}/{{ns}}.json`,
    // },

    // interpolation: {
    //   escapeValue: false, // not needed for react as it escapes by default
    // },
    // react: {
    //   useSuspense: false,
    // },
    // detection: {
    //   // order and from where user language should be detected
    //   order: [
    //     "localStorage",
    //     "htmlTag",
    //   ],

    // //   // keys or params to lookup language from
    //   lookupLocalStorage: "i18nextLng",
    //   lookupSessionStorage: "i18nextLng",
    //   lookupFromPathIndex: 0,
    //   lookupFromSubdomainIndex: 0,

    // //   // cache user language on
    //   caches: ["localStorage", "cookie"],
    // //   excludeCacheFor: ["cimode"], // languages to not persist (cookie, localStorage)

    // //   // optional expire and domain for set cookie
    // //   cookieMinutes: 10,
    // //   cookieDomain: "myDomain",

    // //   // optional htmlTag with lang attribute, the default is:
    // //   // htmlTag: document.documentElement,

    // //   // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
    // //   // cookieOptions: { path: "/", sameSite: "strict" },
    // },
  })

export default i18n
